@import "src/assets/css/light.scss";
@import '../../../assets/css/light.scss';

.error_container {
  // Applying styles to wrapper and not error_editor because we want to have same look irrespective of using editor or just string
  color: $color-error;
  background-color: $color-error-bg;
  border-radius: $border-radius + unquote('px');
  overflow: hidden;
  padding: $padding-xs + unquote('px');

  .error_editor {
    width: 100%;
    border: unset;
    :global {
      .monaco-editor .margin,
      .monaco-editor-background {
        background-color: $color-error-bg !important;
      }
    }
  }
}
