@import "src/assets/css/light.scss";
.error_message_container {
  display: flex;

  flex-direction: column;
  padding: 0.75rem;
  height: 100%;
  border: 1px solid #cdd4da;
  box-sizing: border-box;
  border-radius: 6px;
  color: #ca4139;

  .error_code {
    display: flex;
    align-items: center;

    margin-bottom: 0.675rem;

    font-size: 0.75rem;
    :first-child {
      margin-right: 0.5rem;
    }
  }

  .error_message {
    flex-grow: 1;
    padding: 0.5rem 0.75rem;
    background: #f9e9e8;
    border-radius: 6px;
    overflow-y: auto;
  }
}
