@import "src/assets/css/light.scss";
@import './assets/css/index.scss';

@font-face {
  font-family: PolySans;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/PolySans-Neutral.woff2') format('woff2');
}

@font-face {
  font-family: PolySans;
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('assets/fonts/PolySans-Median.woff2') format('woff2');
}

@font-face {
  font-family: PolySans;
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/PolySans-MedianItalic.woff2') format('woff2');
}

@font-face {
  font-family: PolySans Wide;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/PolySans-NeutralWide.woff2') format('woff2');
}

@font-face {
  font-family: PolySans Wide;
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/PolySans-MedianWide.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/Inter-SemiBold.woff2') format('woff2');
}
