@import "src/assets/css/light.scss";
.destination_name {
  display: flex;
  align-items: center;

  :first-child {
    margin-right: 0.5rem;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.empty_list_container {
  text-align: center;
  background-color: #ffffff;
  padding: 37px;

  > :first-child {
    font-size: 0.75rem;
    font-weight: 700;
    color: #424b59;
  }
  > :last-child {
    font-size: 13px;
    color: #424b59;
  }

  a {
    color: #005ce6;
  }
}

.disconnect_destination_cta {
  color: #ca4139 !important;
}
