@import "src/assets/css/light.scss";
.error_view_container {
  font-size: 20px !important;

  .error_view {
    height: 50px;
    border-color: #ca4139;
    border-width: thin;
    border-style: solid;
    background: #f9e9e8;
    display: flex;
    align-items: center;
    color: #ca4139;
    border-radius: 2px;
    margin-bottom: 10px;
    flex-direction: column;

    .animated {
      margin: auto;

      .error_button {
        color: #005ce6;
        font-size: 20px !important;
      }
    }
  }
}
