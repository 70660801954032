@import "src/assets/css/light.scss";
.settings_row_container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2rem;

  .settings_info {
    margin-right: 10px;
  }

  .settings_description {
    margin-top: 0.25rem;
    word-break: break-word;
  }
}
