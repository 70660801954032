$blue-1: #f6f9ff;
$blue-2: #ebf3ff;
$blue-3: #c7daff;
$blue-4: #adc9ff;
$blue-5: #5599ff;
$blue-6: #105ed5;
$blue-7: #044ab4;
$blue-8: #13359d;
$blue-9: #002266;
$blue-10: #00113a;
$purple-1: #eae9ff;
$purple-2: #ddd7fc;
$purple-3: #cdbff9;
$purple-4: #b39aff;
$purple-5: #8e6afb;
$purple-6: #7447fc;
$purple-7: #6039d3;
$purple-8: #492ba0;
$purple-9: #291074;
$purple-10: #120338;
$teal-1: #ebf3f1;
$teal-2: #ceeee6;
$teal-3: #a7e6d7;
$teal-4: #58d7b9;
$teal-5: #31d0aa;
$teal-6: #26a889;
$teal-7: #23977b;
$teal-8: #1c7761;
$teal-9: #115746;
$teal-10: #002329;
$green-1: #eaf1ee;
$green-2: #e5f4ea;
$green-3: #c3e4ce;
$green-4: #95d4aa;
$green-5: #58af7c;
$green-6: #2f965d;
$green-7: #006733;
$green-8: #2c5840;
$green-9: #1e4630;
$green-10: #082800;
$magenta-1: #fff0f6;
$magenta-2: #ffd6e7;
$magenta-3: #ffadd2;
$magenta-4: #ff85c0;
$magenta-5: #f759ab;
$magenta-6: #eb2f96;
$magenta-7: #c41d7f;
$magenta-8: #9e1068;
$magenta-9: #780650;
$magenta-10: #520339;
$red-1: #fbeced;
$red-2: #f8d9da;
$red-3: #ebb8bb;
$red-4: #e46969;
$red-5: #db4552;
$red-6: #c23535;
$red-7: #9e2f34;
$red-8: #760521;
$red-9: #5b0117;
$red-10: #340005;
$orange-1: #fcfbf9;
$orange-2: #ffe6d9;
$orange-3: #f8d2b5;
$orange-4: #ffb066;
$orange-5: #fd8f52;
$orange-6: #de5731;
$orange-7: #cb4e01;
$orange-8: #b85722;
$orange-9: #75300a;
$orange-10: #39251b;
$yellow-1: #f6f5f3;
$yellow-2: #fff8e5;
$yellow-3: #fbeeca;
$yellow-4: #fce5a5;
$yellow-5: #fbdd73;
$yellow-6: #f9cc2d;
$yellow-7: #c29c00;
$yellow-8: #977b3c;
$yellow-9: #735502;
$yellow-10: #332118;
$volcano-1: #fff2e8;
$volcano-2: #ffd8bf;
$volcano-3: #ffbb96;
$volcano-4: #ff9c6e;
$volcano-5: #ff7a45;
$volcano-6: #fa541c;
$volcano-7: #d4380d;
$volcano-8: #ad2102;
$volcano-9: #871400;
$volcano-10: #610b00;
$geekblue-1: #f0f5ff;
$geekblue-2: #d6e4ff;
$geekblue-3: #adc6ff;
$geekblue-4: #85a5ff;
$geekblue-5: #597ef7;
$geekblue-6: #2f54eb;
$geekblue-7: #1d39c4;
$geekblue-8: #10239e;
$geekblue-9: #061178;
$geekblue-10: #030852;
$lime-1: #fcffe6;
$lime-2: #f4ffb8;
$lime-3: #eaff8f;
$lime-4: #d3f261;
$lime-5: #bae637;
$lime-6: #a0d911;
$lime-7: #7cb305;
$lime-8: #5b8c00;
$lime-9: #3f6600;
$lime-10: #254000;
$gold-1: #fffbe6;
$gold-2: #fff1b8;
$gold-3: #ffe58f;
$gold-4: #ffd666;
$gold-5: #ffc53d;
$gold-6: #faad14;
$gold-7: #d48806;
$gold-8: #ad6800;
$gold-9: #874d00;
$gold-10: #613400;
$gray-1: #fdfeff;
$gray-2: #fafafa;
$gray-3: #f8fafb;
$gray-4: #ececef;
$gray-5: #e4e6ec;
$gray-6: #bfbfc5;
$gray-7: #a2a3ad;
$gray-8: #6f6f76;
$gray-9: #515156;
$gray-10: #2e2e2e;
$gray-20: #242424;
$gray-30: #171718;
$color-text-base: #2e2e2e;
$color-text-light-solid: #fdfeff;
$color-bg-base: #fdfeff;
$line-width: 1;
$border-radius: 8;
$size-unit: 4;
$size-popup-arrow: 16;
$control-height: 32;
$opacity-image: 1;
$size-step: 4;
$color-primary: #105ed5;
$color-error: #c23535;
$color-warning: #de5731;
$color-success: #2f965d;
$color-info: #515156;
$font-size: 14;
$size-5-xl: 64;
$size-4-xl: 56;
$size-3-xl: 48;
$size-xxl: 40;
$size-xl: 32;
$size-lg: 24;
$size-md: 20;
$size-ms: 16;
$size: 16;
$size-sm: 12;
$size-xs: 8;
$size-xxs: 4;
$control-height-xs: 16;
$control-height-sm: 24;
$control-height-lg: 40;
$control-height-xl: 48;
$border-radius-xs: 2;
$border-radius-sm: 4;
$border-radius-lg: 12;
$border-radius-xl: 24;
$border-radius-xxl: 32;
$border-radius-3-xl: 40;
$border-radius-4-xl: 48;
$border-radius-5-xl: 64;
$border-radius-outer: 4;
$color-text: #2e2e2e;
$color-text-secondary: rgba(46, 46, 46, 0.83);
$color-text-tertiary: rgba(46, 46, 46, 0.7);
$color-text-quaternary: rgba(46, 46, 46, 0.44);
$color-fill: rgba(46, 46, 46, 0.12);
$color-fill-secondary: rgba(46, 46, 46, 0.08);
$color-fill-tertiary: rgba(46, 46, 46, 0.03);
$color-fill-quaternary: rgba(46, 46, 46, 0.02);
$color-bg-container: #fdfeff;
$color-bg-layout: #fdfeff;
$color-bg-elevated: #fdfeff;
$color-bg-spotlight: rgba(46, 46, 46, 0.85);
$color-border: rgba(46, 46, 46, 0.83);
$color-border-secondary: rgba(46, 46, 46, 0.29);
$color-border-tertiary: rgba(46, 46, 46, 0.13);
$color-border-inverse: #fdfeff;
$color-split: rgba(46, 46, 46, 0.83);
$color-split-secondary: rgba(46, 46, 46, 0.29);
$color-split-tertiary: rgba(46, 46, 46, 0.13);
$color-primary-bg: #f6f9ff;
$color-primary-bg-hover: #ebf3ff;
$color-primary-border: #105ed5;
$color-primary-border-hover: #044ab4;
$color-primary-hover: #044ab4;
$color-primary-base: #105ed5;
$color-primary-subdued: #c7daff;
$color-primary-subdued-hover: #adc9ff;
$color-primary-subdued-active: rgba(85, 153, 255, 0.7);
$color-primary-active: #13359d;
$color-primary-text-hover: #13359d;
$color-primary-text: #002266;
$color-primary-text-active: #00113a;
$color-success-bg: #eaf1ee;
$color-success-bg-hover: #e5f4ea;
$color-success-border: #2f965d;
$color-success-border-hover: #006733;
$color-success-hover: #2c5840;
$color-success-base: #2f965d;
$color-success-variant: #006733;
$color-success-subdued: #c3e4ce;
$color-success-subdued-hover: #95d4aa;
$color-success-subdued-active: rgba(88, 175, 124, 0.75);
$color-success-active: #1e4630;
$color-success-text-hover: #2c5840;
$color-success-text: #1e4630;
$color-success-text-active: #082800;
$color-warning-bg: #fcfbf9;
$color-warning-bg-hover: #ffe6d9;
$color-warning-border: #de5731;
$color-warning-border-hover: #cb4e01;
$color-warning-hover: #cb4e01;
$color-warning-base: #de5731;
$color-warning-subdued: #f8d2b5;
$color-warning-subdued-hover: rgba(255, 176, 102, 0.8);
$color-warning-subdued-active: rgba(253, 143, 82, 0.8);
$color-warning-active: #b85722;
$color-warning-text-hover: #b85722;
$color-warning-text: #75300a;
$color-warning-text-active: #39251b;
$color-error-bg: #fbeced;
$color-error-bg-hover: #f8d9da;
$color-error-border: #c23535;
$color-error-border-hover: #9e2f34;
$color-error-hover: #9e2f34;
$color-error-base: #c23535;
$color-error-subdued: #f8d9da;
$color-error-subdued-hover: #ebb8bb;
$color-error-subdued-active: rgba(228, 105, 105, 0.7);
$color-error-active: #760521;
$color-error-text-hover: #760521;
$color-error-text: #5b0117;
$color-error-text-active: #340005;
$color-info-bg: #fafafa;
$color-info-bg-hover: #f8fafb;
$color-info-border: #515156;
$color-info-border-hover: #2e2e2e;
$color-info-hover: #2e2e2e;
$color-info-base: #515156;
$color-info-subdued: #e4e6ec;
$color-info-subdued-hover: rgba(191, 191, 197, 0.8);
$color-info-subdued-active: rgba(162, 163, 173, 0.8);
$color-info-active: #242424;
$color-info-text-hover: #6f6f76;
$color-info-text: #515156;
$color-info-text-active: #2e2e2e;
$color-bg-mask: rgba(46, 46, 46, 0.5);
$steps-dot-size: 8;
$steps-current-dot-size: 10;
$rate-star-size: 20;
$color-fill-content: rgba(46, 46, 46, 0.08);
$color-fill-content-hover: rgba(46, 46, 46, 0.12);
$color-fill-alter: #f8fafb;
$color-bg-container-disabled: rgba(46, 46, 46, 0.08);
$color-bg-container-disabled-subdued: rgba(46, 46, 46, 0.02);
$color-border-bg: #fdfeff;
$color-text-placeholder: rgba(46, 46, 46, 0.7);
$color-text-disabled: rgba(46, 46, 46, 0.44);
$color-text-heading: #2e2e2e;
$color-text-label: #2e2e2e;
$color-text-description: rgba(46, 46, 46, 0.7);
$color-bg-text-hover: rgba(46, 46, 46, 0.08);
$color-bg-text-active: rgba(46, 46, 46, 0.12);
$color-icon: rgba(46, 46, 46, 0.7);
$color-icon-hover: #2e2e2e;
$color-link: #105ed5;
$color-link-hover: #044ab4;
$color-link-active: #13359d;
$color-highlight: #de5731;
$control-outline: #f6f9ff;
$color-warning-outline: #fcfbf9;
$color-error-outline: #fbeced;
$font-size-icon: 12;
$control-line-width: 1;
$control-outline-width: 2;
$control-item-bg-hover: #ebf3ff;
$control-item-bg-active: #c7daff;
$control-item-bg-active-hover: #adc9ff;
$control-interactive-size: 16;
$control-item-bg-active-disabled: rgba(46, 46, 46, 0.08);
$control-tmp-outline: rgba(46, 46, 46, 0.02);
$opacity-loading: 0.65;
$padding: 16;
$padding-xxs: 4;
$padding-xs: 8;
$padding-sm: 12;
$padding-md: 20;
$padding-lg: 24;
$padding-xl: 32;
$padding-xxl: 40;
$padding-3-xl: 48;
$padding-4-xl: 56;
$padding-5-xl: 64;
$margin: 16;
$margin-xxs: 4;
$margin-xs: 8;
$margin-sm: 12;
$margin-md: 20;
$margin-lg: 24;
$margin-xl: 32;
$margin-xxl: 40;
$margin-3-xl: 48;
$margin-4-xl: 56;
$margin-5-xl: 64;
$control-padding-horizontal: 12;
$control-padding-horizontal-sm: 8;
$padding-content-horizontal-lg: 24;
$padding-content-vertical-lg: 16;
$padding-content-horizontal: 16;
$padding-content-vertical: 12;
$padding-content-horizontal-sm: 16;
$padding-content-vertical-sm: 8;
$box-shadow:
  0px 1px 2px 0px rgba(0, 0, 0, 0.03),
  0px 1px 6px -1px rgba(0, 0, 0, 0.02),
  0px 2px 4px 0px rgba(0, 0, 0, 0.02);
$box-shadow-secondary:
  0px 6px 16px 0px rgba(0, 0, 0, 0.08),
  0px 3px 6px -4px rgba(0, 0, 0, 0.12),
  0px 9px 28px 8px rgba(0, 0, 0, 0.05);
$box-shadow-card:
  0px 1px 2px -2px rgba(0, 0, 0, 0.16),
  0px 3px 6px 0px rgba(0, 0, 0, 0.12),
  0px 5px 12px 4px rgba(0, 0, 0, 0.09);
$color-item-text: rgba(253, 254, 255, 0.65);
$color-item-text-disabled: rgba(253, 254, 255, 0.25);
$focus-primary: 0px 0px 0px 2px rgb(0, 0, 0);
$focus-error: 0px 0px 0px 2px rgb(0, 0, 0);
$focus-warning: 0px 0px 0px 2px rgb(0, 0, 0);
$tooltip-bg: #2e2e2e;
$segmented-bg-color-selected: #fdfeff;
$segmented-group-bg: rgba(46, 46, 46, 0.08);
$color-menu-dark-bg: #001529;
$color-menu-sub-item-bg: #000c17;
$box-shadow-button-solid: 0px 2px 0px 0px rgb(0, 0, 0);
$padding-tooltip-content-vertical: 6;
$font-size-sm: 12;
$font-size-lg: 16;
$font-size-xl: 18;
$font-size-heading-1: 38;
$font-size-heading-2: 32;
$font-size-heading-3: 24;
$font-size-heading-4: 22;
$font-size-heading-5: 20;
