@import "src/assets/css/light.scss";
.edit_credentials_popup {
  max-height: 80vh;
  .auth_container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .auth_container__wrapper {
      width: 500px;
    }
  }

  :global {
    .sources_auth__auth_accounts {
      margin-top: 0;
      margin-bottom: 0;

      > div {
        min-width: unset !important;
      }
    }

    .auth_accounts__header {
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 19px;
      text-align: unset !important;
      margin: 20px 0 !important;
    }

    .auth_accounts__header {
      margin-top: 0 !important;
    }

    .form_group_container {
      padding: 0;
      > [color='#000000'] {
        font-weight: 500 !important;
        font-size: 14px;
        line-height: 19px;
        text-align: unset !important;
        margin: 0 0 20px !important;
        padding: 0;
      }

      .form_group__text_area_container {
        padding-bottom: 0;
        margin-bottom: 1rem;
        [class*='p-t-sm'] {
          padding-top: 0;
          width: 100%;

          > :first-child {
            font-size: 0.875rem;
            color: #101836;
            display: block;
            margin-bottom: 0.375rem;
          }
        }
      }

      .form_group__select_container {
        > div {
          width: 100%;
          background: #ffffff;
          box-sizing: border-box;
          border-radius: 6px;
          padding-top: 0;
          > :first-child {
            font-size: 0.875rem;
            color: #101836;
            display: block;
            margin-bottom: 0.375rem;
          }
        }
        :global {
          .ant-select-selector {
            height: 100%;
            border-radius: 6px !important;
            padding: 0 !important;
          }
          .ant-select-arrow .anticon:not(.ant-select-suffix) {
            pointer-events: none;
          }
          .ant-select-selection-item {
            background-color: transparent;
            border: none;
            padding: 0.75rem 1rem;
            line-height: 20px;
          }
          .ant-select-selection-placeholder {
            padding: 0.75rem 1rem;
            line-height: 20px;
          }
        }
      }
    }
  }
}
